import React from 'react';
import Images from '../assets/images/AWS/Index.js';
import '../assets/css/container-skills.css';

function AWS() {
  return (
    <div className="container marketing">
    <hr className="featurette-divider3"/>
    <div className="row featurette">

        <div className="col-md-9 order-md-3">
          <div className="bg-container"></div>
          <div className="nft">
          <span className="container-title">Amazon Web Services</span>
          <p className="container-line" />
            <div className='main'>
            <div className="col-md-12 order-md-4">

              <div className="grid">
                  <div className="span-col-2 grid-title">Almacenamiento</div>
                  <div className="grid-image"><img src={Images.S3} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">S3 Bucket</p>
                  <div className="grid-image"><img src={Images.DynamoDB} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">DynamoDB</p>
                  <div className="grid-image"><img src={Images.ElasticContainerRegistry} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">ECR</p>
                  <div className="grid-image"><img src={Images.Redis} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Redis</p>
                  <div className="grid-image"><img src={Images.RDS} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">RDS</p>
              </div>

              <div className="grid">
                  <div className="span-col-2 grid-title">ETL</div>
                  <div className="grid-image"><img src={Images.Glue} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Glue</p>
                  <div className="grid-image"><img src={Images.LakeFormation} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Lake Formation</p>
              </div>

              <div className="grid">
                  <div className="span-col-2 grid-title">Serverless</div>
                  <div className="grid-image"><img src={Images.Lambda} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Lambda</p>
                  <div className="grid-image"><img src={Images.APIGateway} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">API Gateway</p>
                  <div className="grid-image"><img src={Images.Cloud9} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Cloud 9</p>
              </div>

              <div className="grid">
                  <div className="span-col-2 grid-title">Permisos</div>
                  <div className="grid-image"><img src={Images.Iam} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">I am</p>
                  <div className="grid-image"><img src={Images.Policy} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Policy Generator</p>
              </div>

              <div className="grid">
                  <div className="span-col-2 grid-title">Otros</div>
                  <div className="grid-image"><img src={Images.CloudWatch} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Cloud Watch</p>
                  <div className="grid-image"><img src={Images.Route53} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Route 53</p>
                  <div className="grid-image"><img src={Images.EC2} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">EC2</p>
                  <div className="grid-image"><img src={Images.CloudFront} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Cloud Front</p>
                  <div className="grid-image"><img src={Images.CloudFormation} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">Cloud Formation</p>
                  <div className="grid-image"><img src={Images.VPC} alt="" className="img-desc-aws"/></div>
                  <p className="container-text">VPC</p>
              </div>

              </div>
            </div>
          </div>
        </div>
      


    <div className="col-md-3 order-md-3">
        <img src={Images.Logo} alt="" className="img-AWS img-fluid"/>
    </div>
    </div>
    </div>
  );
}

export default AWS;
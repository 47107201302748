import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/error.css";

const ErrorPage = () => 
{
    return(
    <div>
        <Helmet>
            <title>Error 404</title>
            <body className="body"></body>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            
        </Helmet>
        <div class="night">
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
            <div class="shooting_star"></div>
        </div>
        <div class="error-page">
        <div>
        <h1 data-h1="404">404</h1>
        <br></br><br></br><br></br>
        <h4 data-h1="Ups, La página que buscas no existe!">Ups, La página que buscas no existe!</h4>
        <br></br><br></br><br></br>
        <article>
            <h6 className="text-emoji">😥</h6>
            <button class="bn39" onClick={event =>  window.location.href='/'} ><span class="bn39span">Volver</span></button>
        </article>
        </div>
        
</div>
    </div>
 );
}

export default ErrorPage;
const TechImages = {
    Logo: require('./Logo.png'),

    //#region Services 
    S3: require('./S3.png'),
    Glue: require('./Glue.png'),
    Redis: require('./Redis.png'),
    DynamoDB: require('./DynamoDB.png'),
    Lambda: require('./Lambda.png'),
    Iam: require('./Iam.png'),
    Policy: require('./Policy.png'),
    APIGateway: require('./APIGateway.png'),
    CloudWatch: require('./CloudWatch.png'),
    ElasticContainerRegistry: require('./ElasticContainerRegistry.png'),
    RDS: require('./rds.png'),
    Cloud9: require('./cloud9.png'),
    VPC: require('./VPC.png'),
    Route53: require('./route53.png'),
    LakeFormation: require('./LakeFormation.png'),
    CloudFormation: require('./CloudFormation.png'),
    CloudFront: require('./CloudFront.png'),
    EC2: require('./EC2.png')
}

export default TechImages;
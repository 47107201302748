import React from "react";
import HeaderImages from "../assets/images/Header/index.js";

const Header = () => 
{
    return(
    <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top header-bg">
            <img src={HeaderImages.Logo} alt="img" className="img-icon"/> 
            <div className="navbar-brand">Aarón Varas</div>
            <div className="movement-inner" data-movement-element></div>
        </nav>

        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={HeaderImages.Banner} alt="" />
            <div className="container">
              <div className="carousel-caption text-start">
                <h1>Líder Técnico / Ingeniero de Software Senior</h1>
                <p>Aportando conocimiento con dirección a la innovación y transformación digital</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    );
}

export default Header;
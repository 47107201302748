import React from 'react';
import AboutImages from '../assets/images/About/Index.js';
import CV from '../assets/resources/AaronVaras_CV.pdf';
import '../assets/css/magiccard.css';

function AboutMe() {
  const initialProfessionalExperienceYear = 2017;
  const experienceYears = new Date().getFullYear()-initialProfessionalExperienceYear;
  return (
    <div className="container marketing">
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Aarón Luciano Varas Gatica</h2>
            <h4 ><span>Sobre Mi</span></h4>
            <p className="lead">Ingeniero en informática con {experienceYears} años
            de experiencia en desarrollo de Soluciones informáticas, 
            capacidad de trabajar en equipo, gran empatía, siempre en busca de mejoras y automatización, 
            actualmente generando diseños de soluciones Cloud de AWS con foco en Microservicios, Serverless y GlueETL, 
            Constantemente aprendiendo Arquitectura de Software y diseño de Soluciones.
            </p>
            <h4><span>Información Personal</span></h4>
            <div className="grid">
              <div className="grid-image"><img src={AboutImages.Chile} alt="" className="img-desc"/></div>
              <div>Chileno</div>
              <div className="grid-image"><img src={AboutImages.Calendar} alt="" className="img-desc"/></div>
              <div>07 de Enero de 1994</div>
              <div className="grid-image"><img src={AboutImages.Map} alt="" className="img-desc"/></div>
              <div>Los Ángeles - Chile</div>
            </div>
            
          </div>
          <div className="col-md-4">

          <div className="magic-card">
                <div className="bg uwu"></div>
                <div className="bg"></div>
                <div className="content-card img-fluid">
                <img src={AboutImages.Profile} alt="" className="img-profile img-fluid"/>
                </div>
          </div>

          </div>
          
        </div>
        <br></br>

          <div className="div-center">
            <h4><span>Información de Contacto</span></h4>
            <br></br>

            <div className="grid-container">
            <div className="item2">
            <div className="glass-btn amber-btn">
              <div className="content"></div>
              <a data-toggle="tooltip" data-placement="top" title="Envíame un Correo!" rel="noreferrer" target="_blank" href="mailto:aronvarasg@gmail.com">
              <img src={AboutImages.Gmail} alt="" className="img-personal"/> 
              </a>
            </div>
            </div>
            </div>
            <div className="grid-container">
            <div className="item3">
            <div className="glass-btn green-btn">
              <div className="content"></div>
              <a data-toggle="tooltip" data-placement="top" title="Agregame!" rel="noreferrer" target="_blank" href="https://wa.me/+56927741099?text=Hola!%20te%20contacto%20desde%20tu%20P%C3%A1gina%20Personal%20%F0%9F%9A%80">
              <img src={AboutImages.Whatsapp} alt="" className="img-personal"/> 
              </a>
            </div>
            </div>
            </div>
            
            <div className="grid-container">
            <div className="item4">
            <div className="glass-btn blue-btn">
              <div className="content"></div> 
              <a data-toggle="tooltip" data-placement="top" title="/aaronvarasg" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/aaronvarasg/">
              <img src={AboutImages.Linkedin} alt="" className="img-personal"/> 
              </a>
            </div>
            </div>
            </div>

            <div className="grid-container">
            <div className="item5">
            <div className="glass-btn purple-btn">
              <div className="content"></div> 
              <a data-toggle="tooltip" data-placement="top" title="Currículum Vitae" rel="noreferrer" target="_blank" href={CV} download="AaronVaras_CV.pdf">
              <img src={AboutImages.Link} alt="" className="img-personal"/> 
              </a>
            </div>
            </div>
            </div>
    </div>
  </div> 
  );
}

export default AboutMe;
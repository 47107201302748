const TechImages = {
    Logo: require('./Habilidades.png'),

    //#region Frontend 
    Js: require('./js.png'),
    Html5: require('./html5.png'),
    Css: require('./css.png'),
    Bootstrap: require('./Bootstrap.png'),
    React: require('./reactjs.png'),
    JQuery: require('./jquery.png'),

    //#region Backend 
    Net8: require('./net8.png'),
    NestJs: require('./nestjs.png'),
    NodeJs: require('./nodejs.png'),
    NetS: require('./netframe.png'),
    Python: require('./python.png'),
    MVC: require('./mvc.png'),
    Linq: require('./linq.png'),
    MicroServices: require('./Microservices.png'),
    APIRest: require('./api-rest.png'),
    APISoap: require('./api-soap.png'),
    APIConnect: require('./apiconnect.png'),

    //#region Bases de Datos
    SqlServer: require('./sqlserver.png'),
    MySql: require('./mysql.png'),
    Oracle: require('./oracle.png'),

    //#region Bases de Datos
    AWS: require('./aws.png'),
    Azure: require('./azure.png'),
    Google: require('./google.png'),

    //#region Varios
    Docker: require('./docker.png'),
    VisualStudio2022: require('./visualstudio.png'),
    VisualCode: require('./visualcode.png'),
    Xamarin: require('./xamarin.png'),
    Jenkins: require('./jenkins.png'),
    GitLab: require('./gitlab.png'),
    Postman: require('./postman.png'),
    SoapUI: require('./soapui.png'),
    IntegrationServices: require('./ssis.png'),
    Pentahoo: require('./pentahoo.png')
}

export default TechImages;
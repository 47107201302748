import React, { useState } from 'react';
import FooterImages from '../assets/images/Footer/index.js';

function Footer() {
  const year = new Date().getUTCFullYear();
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) 
    {
      console.log(true)
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };
window.addEventListener('scroll', toggleVisibility);
  return (
    <div >
        <footer className="container">
        <hr className="featurette-divider2"/>
        <p>Aaron Varas ® {year}</p>
        
        {isVisible  && (
          <span className="go-up">
          <img src={FooterImages.Up} alt="" className="img-up" onClick={goToTop} />
          </span>
        )}
      </footer>
    </div>
  );
}

export default Footer;
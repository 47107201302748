import React from 'react';
import TechImages from '../assets/images/Techniques/Index.js';
import '../assets/css/container-skills.css';

function Techniques() {
  return (
      <div className="container marketing">
        <hr className="featurette-divider3"/>
        <div className="row featurette">

          <div className="col-md-9 order-md-4">
          <div className="bg-container"></div>
          <div className="nft">
          <span className="container-title">Habilidades Técnicas</span>
          <p className="container-line" />
            <div className='main'>
            <div className="col-md-11 order-md-4">
            <div className="grid">
              <div className="span-col-2 grid-title">Frontend</div>
              <div className="grid-image"><img src={TechImages.Js} alt="" className="img-desc"/></div>
              <p className="container-text">JavaScript</p>
              <div className="grid-image"><img src={TechImages.Html5} alt="" className="img-desc"/></div>
              <p className="container-text">HTML</p>
              <div className="grid-image"><img src={TechImages.Css} alt="" className="img-desc"/></div>
              <p className="container-text">CSS</p>
              <div className="grid-image"><img src={TechImages.Bootstrap} alt="" className="img-desc"/></div>
              <p className="container-text">Bootstrap</p>
              <div className="grid-image"><img src={TechImages.React} alt="" className="img-desc"/></div>
              <p className="container-text">ReactJs</p>
              <div className="grid-image"><img src={TechImages.JQuery} alt="" className="img-desc"/></div>
              <p className="container-text">JQuery</p>
          </div>

          <div className="grid">
              <div className="span-col-2 grid-title">Backend</div>
              <div className="grid-image"><img src={TechImages.Net8} alt="" className="img-desc"/></div>
              <p className="container-text">Net Core 8</p>
              <div className="grid-image"><img src={TechImages.NodeJs} alt="" className="img-desc"/></div>
              <p className="container-text">NodeJs</p>
              <div className="grid-image"><img src={TechImages.NestJs} alt="" className="img-desc"/></div>
              <p className="container-text">NestJs</p>
              <div className="grid-image"><img src={TechImages.Python} alt="" className="img-desc"/></div>
              <p className="container-text">Python</p>
              <div className="grid-image"><img src={TechImages.NetS} alt="" className="img-desc"/></div>
              <p className="container-text">C#</p>
              <div className="grid-image"><img src={TechImages.MVC} alt="" className="img-desc"/></div>
              <p className="container-text">MVC</p>
              <div className="grid-image"><img src={TechImages.Linq} alt="" className="img-desc"/></div>
              <p className="container-text">Linq</p>
              <div className="grid-image"><img src={TechImages.MicroServices} alt="" className="img-desc"/></div>
              <p className="container-text">MicroServices</p>
              <div className="grid-image"><img src={TechImages.APIRest} alt="" className="img-desc"/></div>
              <p className="container-text">API Rest</p>
              <div className="grid-image"><img src={TechImages.APISoap} alt="" className="img-desc"/></div>
              <p className="container-text">API Soap</p>
          </div>

          <div className="grid">
              <div className="span-col-2 grid-title">Bases de Datos</div>
              <div className="grid-image"><img src={TechImages.SqlServer} alt="" className="img-desc"/></div>
              <p className="container-text">Sql Server</p>
              <div className="grid-image"><img src={TechImages.MySql} alt="" className="img-desc"/></div>
              <p className="container-text">MySql</p>
              <div className="grid-image"><img src={TechImages.Oracle} alt="" className="img-desc"/></div>
              <p className="container-text">Oracle</p>
          </div>

          <div className="grid">
              <div className="span-col-2 grid-title">Cloud</div>
              <div className="grid-image"><img src={TechImages.AWS} alt="" className="img-desc"/></div>
              <p className="container-text">AWS</p>
              <div className="grid-image"><img src={TechImages.Azure} alt="" className="img-desc"/></div>
              <p className="container-text">Azure</p>
              <div className="grid-image"><img src={TechImages.Google} alt="" className="img-desc"/></div>
              <p className="container-text">Google</p>
          </div>

          <div className="grid">
              <div className="span-col-2 grid-title">Varios</div>
              <div className="grid-image"><img src={TechImages.Docker} alt="" className="img-desc"/></div>
              <p className="container-text">Docker</p>
              <div className="grid-image"><img src={TechImages.VisualStudio2022} alt="" className="img-desc"/></div>
              <p className="container-text">Visual Studio 2022</p>
              <div className="grid-image"><img src={TechImages.VisualCode} alt="" className="img-desc"/></div>
              <p className="container-text">Visual Code</p>
              <div className="grid-image"><img src={TechImages.Xamarin} alt="" className="img-desc"/></div>
              <p className="container-text">Xamarin</p>
              <div className="grid-image"><img src={TechImages.Jenkins} alt="" className="img-desc"/></div>
              <p className="container-text">Jenkins</p>
              <div className="grid-image"><img src={TechImages.GitLab} alt="" className="img-desc"/></div>
              <p className="container-text">GitLab</p>
              <div className="grid-image"><img src={TechImages.Postman} alt="" className="img-desc"/></div>
              <p className="container-text">Postman</p>
              <div className="grid-image"><img src={TechImages.SoapUI} alt="" className="img-desc"/></div>
              <p className="container-text">SoapUI</p>
              <div className="grid-image"><img src={TechImages.IntegrationServices} alt="" className="img-desc"/></div>
              <p className="container-text">Integration Services</p>
              <div className="grid-image"><img src={TechImages.Pentahoo} alt="" className="img-desc"/></div>
              <p className="container-text">Pentaho</p>
              <div className="grid-image"><img src={TechImages.APIConnect} alt="" className="img-desc"/></div>
              <p className="container-text">IBM API Connect v10</p>
          </div>

            </div>
            </div>
          </div>
          </div>

        <div className="col-md-3 order-md-2">
            <img src={TechImages.Logo} alt="" className="img-tecnicas img-fluid"/>
        </div>
        </div>
        </div>
  );
}

export default Techniques;
const CertImages = {
    LifeLongLearning: require('./CertiProf-Badge-LLL.png'),
    APIConnect: require('./ibm_apiconnect.png'),
    RemoteWork: require('./CertiProf-Badge-RWVCPC.png'),
    DigitalMarketing: require('./CertiProf-Badge-DMPC.png'),
    Ribbon: require('./ribbon_v2.png'),
    GoogleCloudEssentials: require('./GCE.png'),
    ScrumFoundation: require('./Scrum-Foundation.png'),
    LeanSixSigma: require('./Lean-Six-Sigma.png'),
    BusinessIntelligenceFoundation: require('./Business-Intelligence-Foundation.png'),
    AwsCloudQuestPractitioner: require('./Aws-Cloud-Quest-Cloud-Practitioner.png'),
    AwsCloudQuestArchitect: require('./Aws-Cloud-Quest-Solutions-Architect.png'),
    BusinessModelCanvas: require('./BusinessModelCanvas.png')
}

export default CertImages;
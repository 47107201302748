import React from 'react';
import KnowImages from '../assets/images/Knowledge/Index.js';
import '../assets/css/container-skills.css';

function Knowledge() {
  return (
    <div className="container marketing">
    <hr className="featurette-divider3"/>
    <div className="row featurette">

      <div className="col-md-9 order-md-4">
        <div className="bg-container"></div>
          <div className="nft">
          <span className="container-title">Otros Conocimientos</span>
          <p className="container-line" />
            <div className='main'>
            <div className="col-md-12 order-md-4">
            <div className="grid">
            <div className="span-col-2 grid-title">Modelado</div>
            <div className="grid-image"><img src={KnowImages.UML} alt="" className="img-desc"/></div>
            <p className="container-text">UML</p>
            <div className="grid-image"><img src={KnowImages.Bizagi} alt="" className="img-desc"/></div>
            <p className="container-text">Bizagi BPMN</p>
            <div className="grid-image"><img src={KnowImages.EnterpriseArch} alt="" className="img-desc"/></div>
            <p className="container-text">Enterprise Architect</p>
          </div>

          <div className="grid">
            <div className="span-col-2 grid-title">IDE Bases de Datos</div>
            <div className="grid-image"><img src={KnowImages.SqlStudio} alt="" className="img-desc"/></div>
            <p className="container-text">Sql Server Studio</p>
            <div className="grid-image"><img src={KnowImages.Navicat} alt="" className="img-desc"/></div>
            <p className="container-text">Navicat</p>
            <div className="grid-image"><img src={KnowImages.Dbeaver} alt="" className="img-desc"/></div>
            <p className="container-text">Dbeaver</p>
            <div className="grid-image"><img src={KnowImages.Toad} alt="" className="img-desc"/></div>
            <p className="container-text">Toad</p>
          </div>

          <div className="grid">
            <div className="span-col-2 grid-title">Versionado de Código</div>
            <div className="grid-image"><img src={KnowImages.GitLab} alt="" className="img-desc"/></div>
            <p className="container-text">GitLab</p>
            <div className="grid-image"><img src={KnowImages.Bitbucket} alt="" className="img-desc"/></div>
            <p className="container-text">Bitbucket</p>
            <div className="grid-image"><img src={KnowImages.Github} alt="" className="img-desc"/></div>
            <p className="container-text">Github</p>
            <div className="grid-image"><img src={KnowImages.Tortoise} alt="" className="img-desc"/></div>
            <p className="container-text">Tortoise SVN</p>
          </div>

          <div className="grid">
            <div className="span-col-2 grid-title">Gestión</div>
            <div className="grid-image"><img src={KnowImages.Jira} alt="" className="img-desc"/></div>
            <p className="container-text">Jira</p>
            <div className="grid-image"><img src={KnowImages.Trello} alt="" className="img-desc"/></div>
            <p className="container-text">Trello</p>
          </div>

          <div className="grid">
            <div className="span-col-2 grid-title">Comunicación</div>
            <div className="grid-image"><img src={KnowImages.Teams} alt="" className="img-desc"/></div>
            <p className="container-text">Teams</p>
            <div className="grid-image"><img src={KnowImages.Slack} alt="" className="img-desc"/></div>
            <p className="container-text">Slack</p>
          </div>

            </div>
        </div>
        </div>
      </div>


    <div className="col-md-3 order-md-3">
        <img src={KnowImages.Logo} alt="" className="img-otrosConocimientos img-fluid"/>
    </div>
    
    </div>


    <div className="div-center">
          <h4><span>Nivel de Inglés</span></h4>
          <div className="grid-ingles">
            <div>Hablado</div>
            <div>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
          </div>
          <div className="grid-ingles">
            <div>Lectura</div>
            <div>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </div>
          </div>
          <div className="grid-ingles">
            <div>Escritura</div>
            <div>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </div>
          </div>
          <div className="grid-ingles">
            <div>Auditivo</div>
            <div>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </div>
          </div>
          </div>
          <hr className="featurette-divider2"/>
    </div>      
  );
}

export default Knowledge;
const AboutImages = {
    Profile: require('./Profile.png'),
    Dni: require('./dni.png'),
    Chile: require('./chile.png'),
    Calendar: require('./calendar.png'),
    Map: require('./Map.png'),
    Gmail: require('./Gmail_logo.png'),
    Whatsapp: require('./Whatsapp_logo.png'),
    Linkedin: require('./Linkedin_logo.png'),
    Link: require('./Link_logo.png')
}

export default AboutImages;
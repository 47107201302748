const KnowImages = {
    Logo: require('./idea.png'),
    UML: require('./uml.png'),
    Bizagi: require('./bizagi.png'),
    EnterpriseArch: require('./enterprise.png'),
    SqlStudio: require('./sqlmanagement.png'),
    Navicat: require('./navicat.png'),
    Dbeaver: require('./dbeaver.png'),
    Toad: require('./toad.png'),
    GitLab: require('./gitlab.png'),
    Bitbucket: require('./bitbucket.png'),
    Github: require('./github.png'),
    Tortoise: require('./tortoise.png'),
    Jira: require('./jira.png'),
    Trello: require('./trello.png'),
    Teams: require('./teams.png'),
    Slack: require('./slack.png')
}

export default KnowImages;
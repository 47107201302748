import React from 'react';
import Header from "../layout/Header";
import Certificates from "./Certificates";
import AboutMe from './AboutMe';
import Techniques from './Techniques';
import AWS from './AWS';
import Knowledge from './Knowledge';
import Footer from '../layout/Footer';
import '../assets/css/style.css';

function Home() {
  return (
    <div className="App">
      <Header></Header>
      <AboutMe></AboutMe>
      <Techniques></Techniques>
      <AWS></AWS>
      <Knowledge></Knowledge>
      <Certificates></Certificates>
      <Footer></Footer>
    </div>
  );
}

export default Home;
import React from "react";
import CertImages from "../assets/images/Certificates/index.js";
import '../assets/css/containers.css';
import '../assets/css/buttons.css';

const Certificates = () => 
{
    return(
        <div className="container marketing">
        <div className="div-center">
        <img src={CertImages.Ribbon} alt="" className="img-ribbonV2"/>
        <br></br>
            <div className="grid">
            <h2 className="featurette-headingv2">Certificaciones</h2>
            </div>
        </div>
        <br></br>
        <div className="row">

          <div className="col-lg-3">
          <div className="container-info">
            <div className="container-info__border"></div>
            <div className="container-info_title__container">
              <span className="container-info_title">Lifelong Learning</span>
              <p className="container-info_paragraph">
              El aprendizaje permanente es una forma de educación autoiniciada que se centra en el desarrollo personal
              </p>
            </div>
            <p className="line" />
            <div className="container-ribbon">
            <img src={CertImages.LifeLongLearning} alt="" className="img-ribbon-card"/>
            </div>
            <button className="btn-blueneon" onClick={() => { window.open("https://www.credly.com/badges/9cc3ba00-68d1-4a49-ae79-e66d459fb585", "_blank"); }} >Ver Certificado »</button> 
          </div>
          </div>

          <div className="col-lg-3">
          <div className="container-info">
            <div className="container-info__border"></div>
            <div className="container-info_title__container">
              <span className="container-info_title">Scrum Foundation</span>
              <p className="container-info_paragraph">
              Conocimiento en descripcion general y comprensión en las teorias y practicas del marco de trabajo Scrum
              </p>
            </div>
            <p className="line" />
            <div className="container-ribbon">
            <img src={CertImages.ScrumFoundation} alt="" className="img-ribbon-card"/>
            </div>
            <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/0b575fd4-a7af-48ce-9fab-91bd85e7955b", "_blank"); }} >Ver Certificado »</button>
          </div>
          </div>

          <div className="col-lg-3">
          <div className="container-info">
            <div className="container-info__border"></div>
            <div className="container-info_title__container">
              <span className="container-info_title">Remote Worker</span>
              <p className="container-info_paragraph">
              Conoce las prácticas, herramientas y técnicas aceptadas mundialmente para realizar el Trabajo Remoto
              </p>
            </div>
            <p className="line" />
            <div className="container-ribbon">
            <img src={CertImages.RemoteWork} alt="" className="img-ribbon-card"/>
            </div>
            <button className="btn-blueneon" onClick={() => { window.open("https://www.credly.com/badges/71095153-208a-43fc-bc03-83f71f776b72", "_blank"); }} >Ver Certificado »</button> 
          </div>
          </div>

          <div className="col-lg-3">
          <div className="container-info">
            <div className="container-info__border"></div>
            <div className="container-info_title__container">
              <span className="container-info_title">Digital Marketing</span>
              <p className="container-info_paragraph">
              Conocimiento detallado de los conceptos, estrategias y aplicaciones de Marketing Digital
              </p>
            </div>
            <p className="line" />
            <div className="container-ribbon">
            <img src={CertImages.DigitalMarketing} alt="" className="img-ribbon-card"/>
            </div>
            <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/6b37a7d5-711a-4e5c-9731-7fa2808b277a", "_blank"); }} >Ver Certificado »</button>
          </div>
          </div>

        </div>
        <br></br>
        <div className="row">

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">Lean Six Sigma - White Belt</span>
                <p className="container-info_paragraph">
                Tiene las habilidades y capacidades para comprender los conceptos estadísticos básicos relacionados con Six Sigma y sus ideas
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.LeanSixSigma} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/867db7a4-5477-4fb6-bee9-9b3799cae1af", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">Business Intelligence Foundation</span>
                <p className="container-info_paragraph">
                Entiende la Historia, el Proceso de Implementación, la Arquitectura y el tipo de Análisis de BI, La inteligencia empresarial
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.BusinessIntelligenceFoundation} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/7b9ee730-fce4-4e83-83ac-7e1281f751a5", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">Business Model Canvas Essentials</span>
                <p className="container-info_paragraph">
                Comprensión de los diferentes bloques del Business Model Canvas y sus funciones, el Método Lean Startup e Innovación Continua
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.BusinessModelCanvas} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/bd53a3b0-713b-432f-baf1-367280e234c8", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>


          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">IBM API Connect v10</span>
                <p className="container-info_paragraph">
                Comprende los componentes principales de IBM API Connect 10 y cómo crear, asegurar y publicar API's
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.APIConnect} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/fe7ae47b-f5e6-4168-a841-b8848a1ce356", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

      </div>
      <br></br>
      <div className="row">

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">AWS Cloud Quest - Cloud Practitioner</span>
                <p className="container-info_paragraph">
                Conocimientos básicos de creación de soluciones utilizando los servicios de AWS y sus componentes y/o recursos
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.AwsCloudQuestPractitioner} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/c265e384-832b-4415-bb34-38d06044bb28", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">AWS Cloud Quest - Solutions Architect</span>
                <p className="container-info_paragraph">
                Experiencia práctica en la creación de soluciones de AWS seguras, tolerantes a fallos y de alta disponibilidad
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.AwsCloudQuestArchitect} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.credly.com/badges/1ae68eef-f3bf-4be6-b516-2ffb680b437e", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="container-info">
              <div className="container-info__border"></div>
              <div className="container-info_title__container">
                <span className="container-info_title">Cloud Essentials</span>
                <p className="container-info_paragraph">
                Conocimiento de los conceptos básicos y configuración de componentes en Google Cloud
                </p>
              </div>
              <p className="line" />
              <div className="container-ribbon">
              <img src={CertImages.GoogleCloudEssentials} alt="" className="img-ribbon-card"/>
              </div>
              <button className="btn-blueneon" type="button" onClick={() => { window.open("https://www.cloudskillsboost.google/public_profiles/b73fe91b-68f1-4508-897c-595742063a9e/badges/2418239", "_blank"); }} >Ver Certificado »</button>
            </div>
          </div>

      </div>
      <br></br>
      </div>
    );
}

export default Certificates;